import React from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WritingsPage from './pages/WritingsPage';
import ProjectsPage from './pages/ProjectsPage';
import FullArticle from './components/FullArticle';
import FullProject from './components/FullProject';
import Layout from './components/Layout';
import PageTransition from './components/PageTransition';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <Layout>
      <PageTransition>
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/writings" element={<WritingsPage />} />
          <Route path="/writings/:slug" element={<FullArticle />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:slug" element={<FullProject />} />
        </Routes>
      </PageTransition>
    </Layout>
  );
}

export default App;