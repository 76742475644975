const writings = [
  {
    id: 1,
    title: "The Search Problem",
    slug: "the-search-problem",
    excerpt: "Search is broken. How do we fix it?",
    content: 
"Searching for information is a tedious and inefficient process. When you want to find something specific, you would typically turn to Google, sift through search results, read snippets, and perform iterative searches until you eventually find what you need. \n\n" +

"This is a clear problem, and it prompted the numerous AI search tools that exist today. However, many of these tools are misaligned in priorities when compared to the traditional Google search. For example, Perplexity is helpful for summarization as it prioritizes quick understanding at the cost of source credibility and potential hallucination. In contrast, a manual search journey in Google involves continuous evaluation of source relevance and credibility as well as query iterations. These misaligned priorities of AI search tools lead most people to rely on traditional Google search methods, despite how time-consuming they may be. \n\n" +

"But what if we could preserve those same priorities and mimic the natural search process of a human? \n\n\n" +

"# Search Agent" +
"\n\n" +
"I created a search agent that:\n" +
"1. Understands a user's query\n" +
"2. Classifies what kind of search task this is (research paper, technical examples, etc.)\n" +
"3. Performs Google searches\n" +
"4. Evaluates search results for relevancy and credibility\n" +
"5. Performs iterative searches\n" +
"6. Repeats steps 4-5 until all information is found\n\n" +

"![Excalidraw](/images/excalidraw.png)\n\n" +

"I believe that search is an iterative process—It’s rare that one page of search results will capture the full nuance of what the user is looking for. I addressed this by making my search agent reason about whether a source is relevant and what kind of information it is missing to perform iterative searches. \n\n" +

"I also built this on top of Google search (it currently works as a chrome extension that is prompted by any search query starting with the tag “a:”). Mainly for the ease of mind that it has the most up to date index and people know that it’s reliable. Through my previous attempts of trying to create my own search engine, I realized the difficulty in getting people to change their habits of using Google search. Google not only has the most up to date information, but people also trust it over other search engines. So I decided to build on top of it and enhance people’s existing search experience.\n\n\n" +

"# Implementation\n\n" +

"The user’s query goes through an initial classification step, where it is categorized into the appropriate search type—such as research papers, technical examples, and so on\n\n" +

"Based on this classification, an initial search query is generated by appending the user's query with \"site:\" operators relevant to the search type. For this, I used GPT-4o-mini for query classification and a rule-based method for adding the appropriate \"site:\" operators.\n\n" +

"The initial search results, along with the user's original query, are then processed through a reranker model. Depending on the context, this could be a cross-encoder (fine-tuned BAAI/bge-reranker-v2-m3) or an LLM (GPT-4o). When using the cross-encoder, results with similarity scores exceeding a certain threshold are classified as relevant. These relevant results, together with the user's original query, are then passed to the LLM to identify any gaps in the information and to generate additional search queries as needed\n\n" +

"These additional queries are executed in parallel, and their results are re-evaluated for relevance and credibility in the context of the previously identified gaps and the user's original query. This iterative process continues until all necessary information is obtained, after which the final results are presented to the user. During this entire process, a set is used with the title or link as identifiers to prevent duplicates across searches.\n\n" +

"This general approach varies slightly depending on the specific type of search query, with adjustments in the models used, prompts, weights, and other parameters.\n\n\n" +


"# Example\n\n" +

"To demonstrate this, let’s say we want to find: “Research papers on cross encoders, specifically focusing on how it compares to other reranking methods”\n\n" +

"In this case, I would want papers that focus on the comparisons between reranking methods.\n\n" +

"Google gives me this:\n\n" +

"![Google version](/images/googleversion.png)\n\n" +

"Which has one good source, “A Thorough Comparison of Cross-Encoders and LLMs for …”. But this source is repeated 4 times, and the results start to get irrelevant after these first few.\n\n" +

"My search agent gives me this:\n\n" +

"![my version](/images/myversion.jpg)\n\n" +

"Which actually gave me what I wanted. These are results I would have gotten through doing iterative Google searches manually.\n\n\n" +

"# Future Directions\n\n" +
"I think it would be interesting to explore how to make this search agent align even more closely with the way humans think and reason. The goal is to create an agent that not only mimics the natural search process but also taps into the cognitive strategies we use when evaluating and synthesizing information.\n\n" +

"Another direction to explore could be this agent’s integration with other existing AI search tools to enhance them with more relevant and credible sources that align with human judgment. One example could be a more reliable Perplexity—one that doesn't just provide quick answers from the first few results of a search engine but also uses the kind of sources you'd trust.\n"

  ,
    date: "2024-08-09"
  },
  {
    id: 2,
    title: "Why do we use graphs? GNNs, Knowledge Graphs, and the Brain",
    slug: "why-do-we-use-graphs",
    excerpt: "A cognitive science and neuroscience perspective on graph-based representations of knowledge",
    content: 
    "# Introduction to Graphs \n\n" +

    "We’re familiar with using graphs—in graph neural networks (GNNs), knowledge graphs, etc. but we rarely question why we use this structure in the first place. \n\n" +
    "The idea of cognitive maps has been around for decades, first proposed by <a href='https://pubmed.ncbi.nlm.nih.gov/18870876/' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>Tolman</a> who suggested that animals form representations of environments in a way that is more complex than a series of stimulus-response associations. Previously, the dominant behaviorist view was that learning was purely based on associations between stimuli and responses so this shifted the way we think about mental representations. \n\n" +
    "Since then, two major approaches emerged on the nature of these cognitive maps: euclidean maps and cognitive graphs. Euclidean maps organize concepts/ideas along continuous dimensions in a semantic space with precise metric properties (imagine plotting animals as coordinates on a 2D space with legs length vs neck length). In contrast, a cognitive graph focuses on the connections and relationships between concepts rather than their position on a fixed continuous space (you could imagine a semantic network where a “dog“ is connected to “animal”). I’ll mainly be focusing on this graph-based approach as they serve as better representations for conceptual knowledge for the following reasons:\n\n" +
    "- Semantic relationships are often discrete rather than continuous, which graphs are able to capture\n" +
    "- Graphs allow for hierarchical structures\n" +
    "- Graph structures support types of inference (like transitive reasoning) which are used in semantic reasoning\n" +

    "![graph 1](/images/graphs1.png)\n\n" +

    "*Our knowledge is likely represented by a combination of both euclidean maps and cognitive graphs, but it depends on the case. If we’re comparing items along specific dimensions, then maps are used. But if we’re learning a new concept (engaging in complex reasoning and knowledge manipulation), graphs are more fitting.*\n\n" +

    "# CogSci/Neuro Backing \n\n" +

    "Graphs seem like an intuitive option for structuring our knowledge; just from tracing our own thought processes, we notice that we often think through associations in a graph-like manner. We can make these inferences based on intuition, but there is also cognitive science and neuroscience research that show specific neural activity patterns corresponding to the graph-like structure of information processing.\n\n" +
    "Cognitive science research (based on behavioral data like response time) and neuroscience research (based on brain activity data like fMRI studies) are used in conjunction to provide a comprehensive overview.\n\n" +
    "We start off with an assumption of how the human mind operates. In this case, the assumption is that humans organize knowledge in a graph-like structure (imagine a knowledge graph with nodes to represent entities and edges to represent relationships).\n\n" +

    "![graph 2](/images/graphs2.png)\n\n" +
    "*Example of a knowledge graph*\n\n" +

    "Starting from the higher-level behavioral experiments,classical studies have shown faster response times for concepts that correlate to the distance in a hypothetical knowledge graph (responding faster to words that are semantically related). We can also look at more rigorous evidence from neuroscience research that conducted multivoxel pattern analysis (MVPA) of fMRI data that found that in several brain regions (left inferior frontal gyrus, left insula, left anterior temporal lobe, and left superior temporal gyrus), the neural patterns for items within the same cluster were more similar than patterns for items in different clusters.\n\n" +

    "![graph 3](/images/graphs3.png)\n\n" +
    "This experiment utilized a graph with a clear community structure and uniform transition probabilities. However, despite this uniformity in transition probabilities, items within a cluster shared more temporal contexts than items in different clusters (items within the same cluster were more likely to occur near each other in sequences). The increased similarity of neural patterns for items within the same cluster suggests that the brain has learned to represent these items similarly, despite no explicit instruction about the structure. This similarity in representation reflects the underlying graph structure because items within a cluster share more temporal contexts in the sequences generated from the graph. This effect cannot be explained by simple associative learning of transitions, as all transitions were equally likely. Instead, it reflects the higher-order structure of the graph—the community structure.\n\n" +
    
    "It’s interesting to explore this synergy between human cognition and artificial systems—such as the usage of graph neural networks for protein structure prediction and brain network studies. The fundamental reasons behind the adoption of these technologies often goes overlooked, yet it’s important to question as we continue to develop artificial systems aligned to our natural cognitive processes."
    ,
    date: "2024-07-06"
  },
  {
    id: 3,
    title: "A More Human-Like Agent Through Reasoning & Action",
    slug: "react",
    excerpt: "A review over the ReAct framework",
    content: 
    "I’m currently taking an LLM agents class taught by <a href='https://dawnsong.io/' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>Dawn Song</a> and one of our tasks for the class is to read some papers each week on a different topic. I’m actually interested in these papers, so I wanted to write about them as a way to understand them thoroughly. Since I’m writing about them already, I thought I’d share them here as well. \n\n" +
    "<a href='https://arxiv.org/pdf/2210.03629' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>ReAct: Synergizing Reasoning and Acting in Language Models</a> proposes a framework for combining reasoning and acting to perform complex tasks. \n\n" +
    
    "# Problem with Independent Reasoning and Acting \n\n" +
    "Most prior work has focused on either LLM reasoning or acting, which creates a gap between the LLM’s verbal reasoning and its ability to execute tasks. Reasoning traces are generated, but these traces do not always feed directly into action planning and execution.\n\n" +
    "In humans, reasoning and actions are tightly linked, so we use reasoning to inform our next steps in real time. Traditional LLMs, however, require explicit prompts to translate their reasoning into actions, and there's often no feedback loop that allows the model to adjust its reasoning based on the results of its actions. As a result, systems may be able to reason well but struggle to execute tasks that require real-time adjustments or complex decision-making that involves interacting with the environment.\n\n\n" +
    
    "# Dynamic Adaptation \n\n" +

    "The ReAct framework seeks to address this issue by allowing a system to act on its reasoning and adjust its approach based on feedback—similar to how humans dynamically adapt their actions as they think through a problem.\n\n" +
    "This framework includes a few key features, and we can demonstrate this through a cooking analogy:\n\n" +
    "- Progress tracking (“now that everything is cut, I should heat up the pot of water”)\n" +
    "- Exception handling (“I don’t have salt, so let me use soy sauce and pepper instead”)\n" +
    "- External information seeking (“how do I prepare dough? Let me search on the Internet”)\n\n" +
    "Personally, I think that exception handling is one of the most powerful capabilities of ReAct as it reflects how humans handle complex tasks in real-time and adjust plans accordingly. In the real world, these kinds of exceptions are common and some kind of evaluative process is necessary during the process. This is what allows humans to learn quickly and adapt, even under uncertainty.\n\n\n" +

    "# So How Does ReAct Work?\n\n" +

    "We’ll go through two cases that this research experiments with.\n\n"+
    "Case 1: Knowledge-intensive reasoning tasks (multi-hop question answering, fact verification)\n\n" +
    "We add language-based reasoning steps to expand the agent’s action space (choices the agent has at each time step when interacting with its surroundings). Previously, the action space only included external actions, such as commands to query a database in a question answering task.\n\n" +
    "In ReAct, the action space is augmented to include both traditional actions and internal reasoning steps. Traditional actions might be to simply search for a term on Wikipedia for a question answering task. Reasoning adds additional actions to this, like \"I should search for X first, then look for Y,\" or \"this information doesn’t match, so I need to adjust my search.\"\n\n" +
    "These reasoning steps update the context, which helps in generating more informed actions later on. For example, a thought might help the agent realize it needs to search for additional information, thereby influencing the next action it takes.\n\n" +
    "We can go through an example mentioned in the paper:\n\n"+
    "![react 1](/images/react1.png)\n\n" +
    "We aim to answer: \"Aside from the Apple Remote, what other device can control the program Apple Remote was originally designed to interact with?\"\n\n"+
    "Using ReAct, the agent alternates between reasoning and taking actions, allowing it to solve the task effectively:\n\n"+
    "- Thought 1: It reasons that it should search for the program the Apple Remote was originally designed for\n" +
    "- Act 1: It searches for \"Apple Remote\" and discovers that it was designed to control the \"Front Row media center\"\n" +
    "- Thought 2: Realizing this, the agent decides to search for \"Front Row\" to find out what other devices control it\n" +
    "- Act 2 & 3: It searches for \"Front Row\" and finds that it's a discontinued software\n" +
    "- Thought 4: It reasons that Front Row can be controlled by the Apple Remote and by keyboard function keys\n" +
    "- Act 4: It concludes that the correct answer is \"keyboard function keys\"\n" +
    
    "Case 2: Decision making tasks (which involve interacting with complex environments over long periods with sparse rewards)\n\n"+
    "The paper goes over two tasks:\n\n"+
    "1. ALFWorld\n\n"+
    "*Task Description*\n\n"+
    "An agent navigates a simulated household and interacts with objects to achieve a goal (\"examine paper under desklamp\").\n\n"+
    "*Why is this complex?*\n\n"+
    "An agent may need to take over 50 actions to complete a single task, and it requires reasoning about where items are likely to be located.\n\n"+
    "*Process*\n\n"+
    "ReAct is prompted using annotated trajectories from training data, which include both actions (\"go to coffeetable 1\") and reasoning that help break down complex goals (\"I need to find a desklamp, so I should check desks and shelves\"). The thoughts guide the agent in decomposing goals, tracking progress, and reasoning about where objects are likely located.\n\n"+
    "*Conclusion*\n\n"+
    "ReAct is compared to Act-only (agent follows similar actions but without reasoning) and BUTLER (imitation learning agent trained on 105 expert trajectories).\n\n"+
    "![react 2](/images/react2.png)\n\n" +
    "The key advantage of ReAct is that it uses reasoning to plan subgoals and track progress, which leads to better performance in navigating and interacting with the environment.\n\n\n"+
    
    "2. WebShop\n\n"+
    "*Task Description*\n\n"+
    "Online shopping environment where the agent must find and purchase products based on user instructions (\"I am looking for a nightstand with drawers. It should have a nickel finish, and priced lower than $140\").\n\n"+
    "*Why is this complex?*\n\n"+
    "There is a large variety of real-world product data (titles, descriptions, options) and requires the agent to interact with this data through text-based actions.\n\n"+
    "*Process*\n\n"+
    "Thoughts help the agent reason about the product attributes and options based on the user's request. For example, if the instruction is to find a \"space-saving ottoman bench,\" the thought process might help the agent reason that \"39x18x18 inches\" is an acceptable size and \"blue\" is a suitable color.\n\n"+
    "*Conclusion*\n\n"+
    "ReAct’s reasoning helps it bridge the gap between noisy product descriptions and the required attributes in the user instructions, leading to better product selection.\n\n\n"+
    
    "# Closing Thoughts\n\n" +
    "ReAct integrates reasoning and action in a unified method to address limitations in prior work that only focused on one or the other. This approach also produces interpretable decision traces, meaning the reasoning and actions taken are clear and understandable.\n\n" +
    "I recently applied this to DevSearch, which is an agent that performs web retrieval over documentation and GitHub repos to help developers with dev tasks that require up-to-date information. It involves coming up with an initial action based on the user’s codebase and query, then reasoning to determine which steps to take next. It goes within these pages, and determines which actions to take next (click on buttons or hyperlinks) based on what is on the page and what information it still needs. This integration of reasoning and action was necessary for a project like this, as context needed to be constantly updated as the agent went along its journey.\n\n"
    ,
    date: "2024-09-15"
  }
  // You can add more writings here later
];

export default writings;