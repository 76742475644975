import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/sharedStyles.css';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-30 text-gray-900 font-serif">
      <header className="py-6">
        <nav className="container mx-auto px-4">
          <ul className="flex justify-end space-x-6 font-sans">
            <li><NavLink to="/" className={({ isActive }) => isActive ? "underline nav-link" : "nav-link"}>Home</NavLink></li>
            <li><NavLink to="/writings" className={({ isActive }) => isActive ? "underline nav-link" : "nav-link"}>Writings</NavLink></li>
            <li><NavLink to="/projects" className={({ isActive }) => isActive ? "underline nav-link" : "nav-link"}>Projects</NavLink></li>
          </ul>
        </nav>
      </header>
      <main className="flex-grow container mx-auto px-4 py-8">
        {children}
      </main>
    </div>
  );
};

export default Layout;