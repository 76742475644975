const projects = [
    {
      id: 1,
      title: "q:uery",
      slug: "query",
      blurb: "A search agent that reasons like a human",
      image: "/images/querypage.png",
      content: 
      "A search agent that reasons like a human.\n\n" +
      "Give the agent any query in natural language and it browses the web for you. It evaluates sources for credibility and relevancy, picks out the most relevant parts from each source, and performs iterative searches.\n\n" +
      "We know what we want to find, and the information is out there. However, we often go through the tedious process of search in order to get from one to the other. Considering how much we have technically advanced in the past decades, why do we still do this manually?\n\n"+
      "The process of search comprises of two parts:\n\n" +
      "1. Evaluation of search results for relevancy and credibility\n" +
      "2. Iterative searches to get closer to what you want\n\n" +
      "I wanted to see if I could mimic these two parts to mimic the natural search process.\n\n" +

      "# The Product\n\n" +
      "This search agent comes in the form of a chrome extension that intercepts search queries and goes through the iterative search process.\n\n" +
      "Let’s say we want to find: Recent research papers on creating GPU scheduling mechanisms, specifically on building model compilers and schedulers to optimize scheduling systems\n\n" +

      '<video class="custom-video" controls>\n' +
      '  <source src="/videos/demo.mp4" type="video/mp4">\n' +
      '  Your browser does not support the video tag.\n' +
      '</video>\n\n' +

      "### 1. Intercept Google Search\n\n" +
      "Any search query into google that starts with the tag: “q:” is intercepted and sent over to the agent\n\n" +

      "### 2. Classification\n\n" +
      "Using an LLM, the search query is classified into a search type (ex: research paper, technical example, etc.) that each have unique steps follow\n\n" +

      "### 3. Initial Search\n\n" +
      "An initial search is performed using the user’s original query, along with any “site:” operators depending on the search type\n\n"+
      "For example, a search type of “research paper” would be appended by “site:arxiv.org | site:nature.com | site:.org | site:.edu | site:.gov | inurl:doi\"\n\n" +

      "### 4. Evaluation\n\n" +
      "Using a mixture of cross-encoder and LLM, these initial results are evaluated for relevancy and credibility. The agent also reasons on which information is still needed given the user’s original query and search results.\n\n" +

      "### 5. Iterative Search\n\n" +
      "Considering the current search results and what kind of information the agent is still missing, iterative searches are performed. These searches are more specific than the initial search query, and they are performed in parallel.\n\n" +

      "### 6. Repeat\n\n" +
      "Steps 4 (Evaluation) and 4 (Iterative Searches) are repeated until the agent determines that it has found satisfactory results.\n\n" +
      
      "You can try it out <a href='https://querysearch.live' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>here</a>\n\n" +

      "When the user clicks on a link, they are also able to find similar sources by clicking on the “find similar” button.\n\n" +

      "When “find similar” is clicked:\n\n" +
      "1. The current webpage is scraped for the first chunk of its content\n" +
      "2. The most relevant keywords are extracted\n" +
      "3. Considering the type of source (blogpost, research paper, etc.) and the relevant keywords, a new search query is constructed using Google’s advanced search operators\n\n" +

      "When we come across a good source, we often want to find similar sources to it. However, we currently have to come up with these search queries ourselves and there is no intuitive way to search for what “feels similar”.\n\n" +

      "I wanted to create a way to find sources that feel similar without having to think about how to search for it.\n\n" +
      "Currently, this “find similar” feature is only accessible through the chrome extension—which is under review by Google to get approved. The link to download it will be posted here once it is approved. In the meantime, you can join the waitlist on [querysearch.live](https://querysearch.live/)\n\n" +

      "# Why a chrome extension?\n\n" +
      "I initially started off with the idea of creating an entirely new search engine—one dedicated to knowledge-based semantic search (research papers, articles, etc.). I put in the work to build out a working prototype as well.\n\n" +

      "However, I realized two major issues with this after talking to users:\n\n" +
      "1. It’s really difficult to keep an updated index that competes with Google’s\n" +
      "2. Even if the search engine sometimes gave better results, most people are not willing to switch over because they trust Google\n\n" +
      "So I addressed these two issues by:\n\n" +
      "1. Directly using Google’s index\n" +
      "2. Blending this into the natural Google search experience—all the user has to do is start off their Google search with “q:”\n\n" +

      "# Why this iterative process?\n\n" +
      "For a long time, I optimized for speed and tried to deliver results in one step.\n\n" +
      "One of the methods I tried was fine-tuning an LLM to convert natural language search queries into advanced search queries. Despite the research I did into Google’s algorithm leak and multiple iterations of my prototype, I could not deliver consistently good results.\n\n" +
      "Search engines are built to be iterated on by humans—so I had to build my solution around this current structure.\n"
    },
    // Add more projects as needed
  ];
  
  export default projects;