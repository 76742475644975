import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useParams } from 'react-router-dom';
import projects from '../data/projects';
import '../styles/sharedStyles.css';

const FullProject = () => {
  const { slug } = useParams();
  const project = projects.find(p => p.slug === slug);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <article className="max-w-3xl mx-auto">
      <h1 className="text-4xl font-bold mb-4">{project.title}</h1>
      <ReactMarkdown 
        className="prose lg:prose-xl markdown custom-article-font"
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ({node, ...props}) => (
            <div className="flex justify-center">
              <img 
                {...props} 
                src={props.src.startsWith('http') ? props.src : `${process.env.PUBLIC_URL}${props.src}`}
                className="max-w-full h-auto my-8" 
                style={{maxWidth: '550px'}}
              />
            </div>
          ),
        }}
      >
        {project.content}
      </ReactMarkdown>
    </article>
  );
};

export default FullProject;