import React from 'react';
import { useNavigate } from 'react-router-dom';
import writings from '../data/writings';
import '../styles/sharedStyles.css'; // Import the shared styles

const HomePage = () => {
    const navigate = useNavigate();
    const featuredWriting1 = writings[0];
    const featuredWriting2 = writings[1];
    const featuredWriting3 = writings[2];

    const handleWritingClick = (slug) => {
        navigate(`/writings/${slug}`);
    };

    return (
        <div className="min-h-[50vh]">
            <h1 className="text-4xl font-bold mb-4">Kelly Hong</h1>
            <p className="text-xl mb-8">
                I'm interested in making technology more closely aligned to the way we think and reason. Currently, I'm exploring the search problem by mimicking the cognitive processes of a manual search process.
                <br /><br />
                If any of this interests you, feel free to reach out!{' '}
                <br /><br />
                kellyhongsn (at) gmail (dot) com   or   on X: <a href="https://twitter.com/kellyhongsn" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">@kellyhongsn</a>{' '}
            </p>

            <br /><br />
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Featured Writing</h2>
                <div 
                    className="writing-block"
                    onClick={() => handleWritingClick(featuredWriting1.slug)}
                >
                    <h3 className="writing-title">{featuredWriting1.title}</h3>
                    <p className="writing-excerpt">{featuredWriting1.excerpt}</p>
                </div>
                <div 
                    className="writing-block mt-4"
                    onClick={() => handleWritingClick(featuredWriting2.slug)}
                >
                    <h3 className="writing-title">{featuredWriting2.title}</h3>
                    <p className="writing-excerpt">{featuredWriting2.excerpt}</p>
                </div>
                <div 
                    className="writing-block mt-4"
                    onClick={() => handleWritingClick(featuredWriting3.slug)}
                >
                    <h3 className="writing-title">{featuredWriting3.title}</h3>
                    <p className="writing-excerpt">{featuredWriting3.excerpt}</p>
                </div>
            </section>
        </div>
    );
};

export default HomePage;