import React from 'react';
import { Link } from 'react-router-dom';
import projects from '../data/projects';
import '../styles/sharedStyles.css';

const ProjectsPage = () => {
  return (
    <div className="min-h-[50vh]">
      <h1 className="text-3xl font-bold mb-8">My Projects</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {projects.map((project) => (
          <Link to={`/projects/${project.slug}`} key={project.id} className="project-card border rounded-lg overflow-hidden shadow-lg">
            <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h2 className="project-title">{project.title}</h2>
              <p className="text-gray-600 mb-4">{project.blurb}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectsPage;